import React, { useEffect, useState, useRef } from "react";
import { ChevronDown } from "react-feather";
import { Dropdown } from "@axeedge/go-teacher-components";

import Tab from '../../../../../components/Tab';
import Tabs from '../../../../../components/Tabs';
import { monthNames, radiusOpts, lineColors } from "../../../services/utils";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import styles from '../SchoolData.module.scss';
import cx from 'classnames';


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const ReadingLogsLineChart = ({
    withBRNA, // Wether the component supports BoomReader National Average
    sourceData, // Data without BoomReader National Average
    averageData, // BoomReader National Average
    requestAverageDataCallback, // Callback to execute when BoomReader National Average is checked
    boomReaderNationalAverageSelected // The state of the BoomReader National Average selection
  }) => {
    const chartRef = useRef(null);

    const [viewType, setViewType] = useState('monthly');
    const [currentTab, setCurrentTab] = useState('total');
    const [selectedData, setSelectedData] = useState(sourceData.monthlyReportData);

    const displayOptions = [
        { text: 'Monthly', type: 'monthly', action: () => setViewType('monthly'), styleName: cx({ 'u-text-primary': viewType === 'monthly' }) },
        { text: 'Yearly', type: 'yearly', action: () => setViewType('yearly'), styleName: cx({ 'u-text-primary': viewType === 'yearly' }) },

    ];
    const viewTitle = displayOptions.find(o => o.type === viewType)?.text || '';

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top'
            },
            title: {
                display: false,
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                // ticks: {
                //     stepSize: 1
                // }
            },
            x: {
                offset: selectedData.length === 0 ? true : false
            }
        }
    };

    const monthNumbers = sourceData.monthlyReportData.map(mr => mr.month);
    let monthNumbersMerged = [];
    const numberOfStudentsInSchool = sourceData.students.length;
    console.log('No of students: ', numberOfStudentsInSchool);

    useEffect(() => {
        if (viewType === 'monthly') {
            setSelectedData(sourceData.monthlyReportData);
        } else if (viewType === 'yearly') {
            setSelectedData(sourceData.academicYearReports.sort((a, b) => a.academicYear - b.academicYear));
        }
        chartRef.current.update();

    }, [viewType, setSelectedData]);

    let labels = [];
    if (averageData && boomReaderNationalAverageSelected) {
        // Merge existing data and brna
        const monthNumbersAverage = averageData.academicMonthlyReports.map(mr => mr.month);
        monthNumbersMerged = [...new Set([...monthNumbers, ...monthNumbersAverage])];
        labels = viewType === 'yearly' ? [...new Set([...selectedData.map(item => item.academicYear), ...averageData.academicMonthlyReports.map(item => item.year)]) ]:
        monthNumbersMerged.map((month) => monthNames[month - 1]);
    } else {
        // Use existing data without BRNA
        labels = viewType === 'yearly' ? selectedData.map(item => item.academicYear) : [...new Set(monthNumbers.map(month => monthNames[month - 1]))];
    }


    const computeDataLogs = (graphData, key, isAverage = false) => {
        const dataForLabels = [];
        labels.forEach((label, index) => {
            if (viewType === 'monthly') {
                const labelIndex = monthNames.indexOf(label);
                const dataForLabelIndex = graphData.filter((item) => item.month - 1 === labelIndex);
                if (dataForLabelIndex) {
                    dataForLabels.push(dataForLabelIndex.reduce((acc, curr) => acc + curr[key], 0))
                }
            } else {
               const dataForLabel = graphData.filter((item) => isAverage ? item.year === label : item.academicYear === label);
               if (dataForLabel) {
                    dataForLabels.push(dataForLabel.reduce((acc, curr) => acc + curr[key], 0))
                }
            }

            if (isAverage) {
                dataForLabels[index] = dataForLabels[index] * numberOfStudentsInSchool;
            }
          
        });
        return dataForLabels;
    };

    let dataLogs = {
        labels,
        datasets: [
            {
                label: 'Reading Logs',
                data: computeDataLogs(selectedData, 'totalLogs'),
                ...lineColors[0],
                ...radiusOpts
            },
        ],
    };

    if (averageData && boomReaderNationalAverageSelected) {
        dataLogs.datasets.push({
            label: 'BRNA',
            data: computeDataLogs(averageData.academicMonthlyReports, 'totalLogs', true),
            ...lineColors[2],
            ...radiusOpts
        });
    }

    let dataBooks = {
        labels,
        datasets: [
            {
                label: 'Books Completed',
                data: computeDataLogs(selectedData, 'totalBooksCompleted'),
                ...lineColors[0],
                ...radiusOpts
            },
        ],
    }

    if (averageData && boomReaderNationalAverageSelected) {
        dataBooks.datasets.push({
            label: 'BRNA',
            data:  computeDataLogs(averageData.academicMonthlyReports, 'totalBooks', true),
            ...lineColors[2],
            ...radiusOpts
        });
    }

    let breakdownLogsData = {
        labels,
        datasets: [
            {
                label: 'Parent Logs',
                data: computeDataLogs(selectedData, 'parentLogs'),
                ...lineColors[0],
                ...radiusOpts
            },
            {
                label: 'Student Logs',
                data: computeDataLogs(selectedData, 'studentLogs'),
                ...lineColors[1],
                ...radiusOpts
            },
            {
                label: 'Teacher Logs',
                data: computeDataLogs(selectedData, 'teacherLogs'),
                ...lineColors[2],
                ...radiusOpts
            },
        ],
    }

    if (averageData && boomReaderNationalAverageSelected) {
        breakdownLogsData.datasets.splice(1, 0, {
            label: 'BRNA Parent logs',
            data: computeDataLogs(averageData.academicMonthlyReports, 'avgParentLogs', true),
            ...lineColors[3],
            ...radiusOpts
        });
        breakdownLogsData.datasets.splice(3, 0, {
            label: 'BRNA Student logs',
            data: computeDataLogs(averageData.academicMonthlyReports, 'avgStudentLogs', true),
            ...lineColors[4],
            ...radiusOpts
        });

        breakdownLogsData.datasets.splice(5, 0, {
            label: 'BRNA Teacher logs',
            data: computeDataLogs(averageData.academicMonthlyReports, 'avgTeacherLogs', true),
            ...lineColors[5],
            ...radiusOpts
        });
    }

    let breakDownBooksData = {
        labels,
        datasets: [
            {
                label: 'Group Books',
                data: computeDataLogs(selectedData, 'groupBooksCompleted'),
                ...lineColors[0],
                ...radiusOpts
            },

            {
                label: 'Home Books',
                data: computeDataLogs(selectedData, 'homeBooksCompleted'),
                ...lineColors[1],
                ...radiusOpts
            },
            {
                label: 'Library Books',
                data: computeDataLogs(selectedData, 'libraryBooksCompleted'),
                ...lineColors[2],
                ...radiusOpts
            },
            {
                label: 'School Books',
                data: computeDataLogs(selectedData, 'schoolBooksCompleted'),
                ...lineColors[3],
                ...radiusOpts
            },
        ],
    };

    if (averageData && boomReaderNationalAverageSelected) {
            breakDownBooksData.datasets.splice(1, 0, {
                label: 'BRNA Group Books',
                data: computeDataLogs(averageData.academicMonthlyReports, 'avgGroupBooks', true), 
                ...lineColors[4],
                ...radiusOpts
            });
            breakDownBooksData.datasets.splice(3, 0, {
                label: 'BRNA Home Books',
                data: computeDataLogs(averageData.academicMonthlyReports, 'avgHomeBooks', true),
                ...lineColors[5],
                ...radiusOpts
            });
    
            breakDownBooksData.datasets.splice(5, 0, {
                label: 'BRNA Library Books',
                data: computeDataLogs(averageData.academicMonthlyReports, 'avgLibraryBooks', true),
                ...lineColors[6],
                ...radiusOpts
            });
            breakDownBooksData.datasets.splice(7, 0, {
                label: 'BRNA School Books',
                data: computeDataLogs(averageData.academicMonthlyReports, 'avgSchoolBooks', true),
                ...lineColors[7],
                ...radiusOpts
            });
    }

    const getChartData = () => {
        switch (currentTab) {
            case 'total':
                return dataLogs;
            case 'books':
                return dataBooks;
            case 'logsBreakdown':
                return breakdownLogsData;
            case 'booksBreakdown':
                return breakDownBooksData
            default:
                return dataLogs;
        }
    }

    return (
        <>
            <Tabs className='u-m-base-3'>
                <Tab selected={currentTab === 'total'} onClick={() => setCurrentTab('total')}>Total Reading logs</Tab>
                <Tab selected={currentTab === 'books'} onClick={() => setCurrentTab('books')}>Books completed</Tab>
                <Tab selected={currentTab === 'logsBreakdown'} onClick={() => setCurrentTab('logsBreakdown')}>Reading logs breakdown</Tab>
                <Tab selected={currentTab === 'booksBreakdown'} onClick={() => setCurrentTab('booksBreakdown')}>Books completed breakdown</Tab>
            </Tabs>

            <div className="u-flex-center u-m-base-2 u-justify-between">
                {currentTab === 'total' || currentTab === 'logsBreakdown' ?
                    <h4 className="u-m-base-0 u-bold">Number of reading logs</h4> :
                    <h4 className="u-m-base-0 u-bold">Books completed</h4>
                }
                <span className="small u-text-muted">Data is calculated at the end of each day</span>
            </div>

            <div className="u-flex-center u-m-base-3">
                Display:
                <Dropdown icon={ChevronDown} dropdownOpts={displayOptions} classNames={styles.viewOptionBtn} leftAligned titleLeft={viewTitle} />
            </div>


            { withBRNA &&
                <div className="u-m-base-3">
                    <span className="basic-form__group--check basic-form__group-highlight">
                        <input
                            name="brna-check"
                            className="basic-form__check-box basic-form__check-box-right"
                            type="checkbox"
                            id="brna"
                            checked={boomReaderNationalAverageSelected}
                            onChange={() => requestAverageDataCallback()}
                        />

                        <label className="basic-form__check-label-right" htmlFor="brna">
                            Compare my school to the BoomReader National Average (BRNA)
                        </label>
                
                    </span>
                </div>
            }    
        
            <Line ref={chartRef} options={options} data={getChartData()} />

        </>
    )
}

export default ReadingLogsLineChart;