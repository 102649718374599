import { gql } from 'apollo-boost';

export const CLASS_REPORTS = gql`
query schoolByCode($schoolCode: ID!, $typeId: Int, $logType: [Int!]!, $startDate: String!, $endDate: String!, $threshold: Int!) {
    schoolByCode(schoolCode: $schoolCode) {
        id
        classes(typeId: $typeId) {
            id
            name
            activityReport(startDate: $startDate, endDate: $endDate, logType: $logType, threshold: $threshold) {
               id
               percentage
               previousWeekPercentage
               studentsTotal
               studentsAboveThresholdCounter
               averageCounterPerStudent
               totalLogs
            }
        }
    }
}
`;

export const YEAR_GROUP_REPORTS = gql`
query schoolByCode($schoolCode: ID!, $logType: [Int!]!, $startDate: String!, $endDate: String!, $threshold: Int!) {
    schoolByCode(schoolCode: $schoolCode) {
        id
        yearGroupReports(startDate: $startDate, endDate: $endDate, logType: $logType, threshold: $threshold) {
            id
            year
            percentage
            previousWeekPercentage
            studentsTotal
            studentsAboveThresholdCounter
            averageCounterPerStudent
            totalLogs
        }
    }
}
`;

//Data dashboard
const monthlyReportDataQuery =`
id
month
year
groupBooksCompleted
homeBooksCompleted
libraryBooksCompleted
schoolBooksCompleted
totalBooksCompleted
parentLogs
studentLogs
teacherLogs
totalLogs
`; 


const academicYearReportsQuery = `
id
activeAccounts
purchasedAccounts
activeParents
subscriptionExpiresAt
academicYear
groupBooksCompleted
homeBooksCompleted
libraryBooksCompleted
schoolBooksCompleted
totalBooksCompleted
parentLogs
studentLogs
teacherLogs
totalLogs
topBooksCompleted {
    id
    title
    author
}
`

export const SCHOOL_MONTHLY_REPORT_DATA = gql`
query schoolByCode($schoolCode: ID!, $academicYear: Int!) {
    schoolByCode(schoolCode: $schoolCode) {
        id
        name
        demographics {
            id
            name
            details {
                id
                name 
                value
            }
        }
        academicYearReports {
           ${academicYearReportsQuery}
        }
        monthlyReportData(academicYear: $academicYear) {
            ${monthlyReportDataQuery}
        }
        yearGroups {
            id
            code
            name
        }
        students {
            id
        }
    }
}
`;

export const ACADEMIC_MONTHLY_REPORTS_DATA = gql`
query academicMonthlyReports($academicYear:Int!) {
    academicMonthlyReports(academicYear: $academicYear) {
        academicYear
        year
        avgGroupBooks
        avgHomeBooks
        avgLibraryBooks
        avgParentLogs
        avgSchoolBooks
        avgStudentLogs
        avgTeacherLogs
        month
        studentsCount
        totalLogs
        totalBooks
    }
}
`;

export const GET_SCHOOL_YEAR_GROUPS = gql`
query currentTeacher {
    currentTeacher {
        id
        school {
            id
            yearGroups {
                id
                code
                name
            }  
        }
    }
}
`;
export const YEAR_GROUP_DATA = gql`
query yearGroup($code: Int!, $schoolId: ID!, $academicYear:Int!) {
    yearGroup(code: $code, schoolId: $schoolId) {
        id
        code
        name
        academicYearReports {
            ${academicYearReportsQuery}
        }
        monthlyReportData(academicYear: $academicYear) {
           ${monthlyReportDataQuery}
        }
        demographics {
            id
            name
            details {
                id
                name 
                value
            }
        }
    }
}`;

export const CLASS_REPORT_DATA = gql`
query studentsClass($id: ID!, $academicYear: Int!) {
    studentsClass(id: $id) {
        id
        name
        academicYearReports {
            ${academicYearReportsQuery}
        }
        monthlyReportData(academicYear: $academicYear) {
           ${monthlyReportDataQuery}
        }
        demographics {
            id
            name
            details {
                id
                name 
                value
            }
        }
    }
}
`;

export const CLASS_DEMOGRAPHIC_FILTERED_STUDENTS = gql`
query studentsClass($id: ID!, $demographicsCategory: String!, $value: String!) {
    studentsClass(id: $id) {
        id
        name
        demographicsFilteredStudents(demographicsCategory: $demographicsCategory, value: $value) {
            id
            name
            upn
        }
    }
}
`;

export const YEAR_GROUP_DEMOGRAPHIC_FILTERED_STUDENTS = gql`
query yearGroup($code: Int!, $schoolId: ID!, $demographicsCategory: String!, $value: String!) {
    yearGroup(code: $code, schoolId: $schoolId) {
        id
        name
        demographicsFilteredStudents(demographicsCategory: $demographicsCategory, value: $value) {
            id
            name
            upn
        }
    }
}
`;

export const COMPARE_CLASSES_BY_ID = gql`
query schoolByCode($schoolCode: ID!, $ids: [String!]!, $academicYear: Int!, $filters: [DemographicsFiltersAttributes!]) {
    schoolByCode(schoolCode: $schoolCode) {
        id
        classesByIds(ids: $ids) {
            id
            name
            monthlyReportData(academicYear: $academicYear, filters: $filters) {
                ${monthlyReportDataQuery}
            }
            academicYearReports(filters: $filters) {
                ${academicYearReportsQuery}
            }
            demographics {
                id
                name
                details {
                    id
                    name 
                    value
                }
            }
        }
    }
}
`;

export const COMPARE_YEAR_GROUPS_BY_ID = gql`
query schoolByCode($schoolCode: ID!, $ids: [String!]!, $academicYear: Int!, $filters: [DemographicsFiltersAttributes!]) {
    schoolByCode(schoolCode: $schoolCode) {
        id
        yearGroupsByIds(ids: $ids) {
            id
            name
            monthlyReportData(academicYear: $academicYear, filters: $filters) {
                ${monthlyReportDataQuery}
            }
            academicYearReports(filters: $filters) {
                ${academicYearReportsQuery}
            }
            demographics {
                id
                name
                details {
                    id
                    name 
                    value
                }
            }
        }
    }
}
`;

export const COMPARE_DEMOGRAPHIC_SETS = gql`
query schoolByCode($schoolCode:ID!, $allSchoolStudents: Boolean!, $yearGroupId: ID, $studentsClassId: ID, $filterSets: [DemographicsFiltersSetInput!], $month: Int, $academicYear: Int) {
    schoolByCode(schoolCode: $schoolCode) {
        id
        monthlyReadingDataByDemographics(allSchoolStudents: $allSchoolStudents, yearGroupId: $yearGroupId, studentsClassId: $studentsClassId, filterSets: $filterSets, month: $month, academicYear: $academicYear) {
            id
            filters {
                id
                demographicsCategory
                values
            }
            results {
                id
                academicYear
                month
                data {
                    id
                    totalReadingBooksCompleted
                    totalReadingLogs
                }
            }
        }
    }
}
`;