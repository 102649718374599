import React, { useState } from "react";
import Top20Books from "./components/Top20Books";
import ReadingLogsLineChart from "./components/ReadingLogsLineChart";
import { Link } from "@reach/router";
import compareDemographicsImg from '../../../../images/data/compareDemographicsImg.svg';

import AccountStats from "./components/AccountsStats";
import WebinarBanner from "./components/WebinarBanner";
import moment from "moment";
import styles from './SchoolData.module.scss';
import { SCHOOL_MONTHLY_REPORT_DATA, ACADEMIC_MONTHLY_REPORTS_DATA } from "../../services/graphql";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import { Loader, Button } from "@axeedge/go-teacher-components";
import CompareByDemography from "./components/CompareByDemography";

const DataDashMainContent = ({ currentUser }) => {
    const [seletctByDemography, setSelectByDemography] = useState(false);
    const [boomReaderNationalAverageSelected, setBoomReaderNationalAverageSelected] = useState(false);


    const currentAcadYear = moment().toDate().getMonth() >= 8 ? moment().year() + 1 : moment().year()

    const { data, loading, error } = useQuery(SCHOOL_MONTHLY_REPORT_DATA, {
        variables: {
            schoolCode: currentUser?.school?.schoolCode,
            academicYear: +currentAcadYear
        },
    })

    const [getAcademicMonthlyReports, { data: averageData, error: averageError }] = useLazyQuery(ACADEMIC_MONTHLY_REPORTS_DATA, {
        fetchPolicy: 'no-cache'
    });

    const fetchAcademicMonthlyReports = () => {
        setBoomReaderNationalAverageSelected(!boomReaderNationalAverageSelected);
        if (averageData) {
            return;
        }
        getAcademicMonthlyReports({ variables: {  academicYear: +currentAcadYear } });
    };

    if (averageError) {
        return <p>{averageError.message}</p>
    }

    if (error) {
        return <p>{error.message}</p>
    }
    if (loading) {
        return <Loader />
    }
    if (data) {

        const { schoolByCode: school } = data;

        return (
            <>
                <div className={styles.dashWrapper}>
                    {seletctByDemography ? <CompareByDemography demoCriteria={school.demographics} school={school} schoolCode={currentUser?.school.schoolCode} close={() => setSelectByDemography(false)} /> :
                        <>
                            <h3 className="u-m-base-3">{school.name}</h3>

                            <WebinarBanner />

                            {school.academicYearReports.find(r => r.academicYear === +currentAcadYear) &&
                                <AccountStats statsData={school.academicYearReports.find(r => r.academicYear === +currentAcadYear)} school={true} />
                            }

                            <ReadingLogsLineChart
                                sourceData={school}
                                boomReaderNationalAverageSelected={boomReaderNationalAverageSelected}
                                averageData={averageData}
                                requestAverageDataCallback={fetchAcademicMonthlyReports}
                                withBRNA={true}
                            />

                            <div className={`${styles.row2Cols} ${'u-m-top-3'}`}>
                                <div className='card card-shadow u-m-left-2'>
                                    <div className="card-body u-flex-center">
                                        <div className="u-flex-center">
                                            <img src={compareDemographicsImg} alt='' />
                                            <p className="lead u-bold u-m-left-2">Compare reading data by demography</p>
                                        </div>
                                        <Button className='u-m-left-auto' onClick={() => setSelectByDemography(true)}>Compare</Button>
                                    </div>
                                </div>
                            </div>

                            <div className='card card-shadow u-m-base-3 u-m-top-4'>
                                <div className="card-body u-flex-center">
                                    <div className={styles.row2Cols}>
                                        <div className="u-p-right-2">
                                            <h2 className="heavy u-m-base-2">Year groups</h2>
                                            <ul className='list-group'>
                                                {school.yearGroups.sort((a, b) => +a.code - +b.code).map(yg =>
                                                    <li key={`yg-${yg.id}`} className="list-item u-justify-between u-p-top-2 u-p-base-2">
                                                        <Link to={`/dataDashboard/year/${yg.code}/${currentUser.school.id}`}> <span className="u-bold">{yg.name}</span></Link>
                                                        {/* <a>Xl</a> */}
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                        <div className="u-p-left-2">
                                            <h2 className="heavy u-m-base-2">Classes</h2>
                                            <ul className='list-group'>
                                                {currentUser?.school.classes.map(kls => (
                                                    <li key={`cls-${kls.id}`} className="list-item u-justify-between u-p-top-2 u-p-base-2">
                                                        <Link to={`/dataDashboard/class/${kls.id}`}> <span className="u-bold"> {kls.name}</span></Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {school.academicYearReports.find(r => r.academicYear === +currentAcadYear) &&
                                <Top20Books books={school.academicYearReports.find(r => r.academicYear === +currentAcadYear)?.topBooksCompleted} />
                            }
                        </>
                    }
                </div>
            </>
        )
    }

    return null;
}

export default DataDashMainContent;