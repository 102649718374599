export const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export const radiusOpts = {
    pointRadius: 5,
    pointHoverRadius: 8
}
export const lineColors = [
    {
        //blue
        borderColor: 'rgb(53, 161, 235)', 
        backgroundColor: 'rgba(31, 139, 213, 1)',
    },
    {
        //green
        borderColor: 'rgb(136, 163, 0)',
        backgroundColor: 'rgba(136, 163, 0, 1)',
    },
    {
        //red
        borderColor: 'rgb(253, 100, 132)',
        backgroundColor: 'rgba(253, 100, 132, 1)',
    },
    {
        //yellow
        borderColor: 'rgb(255, 205, 86)',
        backgroundColor: 'rgba(255, 205, 86, 1)',
    },

    {
        //purple
        borderColor: 'rgb(255, 81, 47)',
        backgroundColor: 'rgba(255, 81, 4, 1)',
    },
    {
        borderColor: 'rgb(45, 57, 159)',//dark blue
        backgroundColor: 'rgba(45, 57, 159, 1)',
    },
    {
        borderColor: 'rgb(0,100,0)',//dark green
        backgroundColor: 'rgba(0, 100, 0, 1)',
    },
    {
        borderColor: 'rgb(139,0,0)',//dark red
        backgroundColor: 'rgba(139,0,0, 1)',
    }
]


export const school = {id:1, classesByIds:[
    {
        id: "U3R1ZGVudHNDbGFzcy0zNjc=",
        name: "10C/Gg1",
        monthlyReportData: [],
        academicYearReports: [],
        demographics: [
            {
                id: "gender",
                name: "gender",
                details: [
                    {
                        id: "gender_",
                        name: "",
                        value: 14,
                        __typename: "DemographicsItem"
                    }
                ],
                __typename: "Demographics"
            },
            {
                id: "ethnicity",
                name: "ethnicity",
                details: [
                    {
                        id: "ethnicity_Black - Nigerian",
                        name: "Black - Nigerian",
                        value: 1,
                        __typename: "DemographicsItem"
                    },
                    {
                        id: "ethnicity_Croatian",
                        name: "Croatian",
                        value: 1,
                        __typename: "DemographicsItem"
                    },
                    {
                        id: "ethnicity_Serbian",
                        name: "Serbian",
                        value: 1,
                        __typename: "DemographicsItem"
                    },
                    {
                        id: "ethnicity_White Eastern European",
                        name: "White Eastern European",
                        value: 1,
                        __typename: "DemographicsItem"
                    },
                    {
                        id: "ethnicity_White - English",
                        name: "White - English",
                        value: 9,
                        __typename: "DemographicsItem"
                    },
                    {
                        id: "ethnicity_White - Irish",
                        name: "White - Irish",
                        value: 1,
                        __typename: "DemographicsItem"
                    }
                ],
                __typename: "Demographics"
            },
            {
                id: "eal",
                name: "eal",
                details: [
                    {
                        id: "eal_false",
                        name: "false",
                        value: 2,
                        __typename: "DemographicsItem"
                    },
                    {
                        id: "eal_true",
                        name: "true",
                        value: 3,
                        __typename: "DemographicsItem"
                    },
                    {
                        id: "eal_",
                        name: "",
                        value: 9,
                        __typename: "DemographicsItem"
                    }
                ],
                __typename: "Demographics"
            },
            {
                id: "send",
                name: "send",
                details: [
                    {
                        id: "send_false",
                        name: "false",
                        value: 3,
                        __typename: "DemographicsItem"
                    },
                    {
                        id: "send_",
                        name: "",
                        value: 11,
                        __typename: "DemographicsItem"
                    }
                ],
                __typename: "Demographics"
            },
            {
                id: "premium",
                name: "premium",
                details: [
                    {
                        id: "premium_false",
                        name: "false",
                        value: 11,
                        __typename: "DemographicsItem"
                    },
                    {
                        id: "premium_true",
                        name: "true",
                        value: 3,
                        __typename: "DemographicsItem"
                    }
                ],
                __typename: "Demographics"
            }
        ],
        __typename: "StudentsClass"
    },
    {
        id: "U3R1ZGVudHNDbGFzcy0zNzE=",
        name: "10A/Dr1",
        monthlyReportData: [],
        academicYearReports: [],
        demographics: [
            {
                id: "gender",
                name: "gender",
                details: [
                    {
                        id: "gender_",
                        name: "",
                        value: 11,
                        __typename: "DemographicsItem"
                    }
                ],
                __typename: "Demographics"
            },
            {
                id: "ethnicity",
                name: "ethnicity",
                details: [
                    {
                        id: "ethnicity_Bangladeshi",
                        name: "Bangladeshi",
                        value: 1,
                        __typename: "DemographicsItem"
                    },
                    {
                        id: "ethnicity_Black - Nigerian",
                        name: "Black - Nigerian",
                        value: 1,
                        __typename: "DemographicsItem"
                    },
                    {
                        id: "ethnicity_Italian",
                        name: "Italian",
                        value: 1,
                        __typename: "DemographicsItem"
                    },
                    {
                        id: "ethnicity_Japanese",
                        name: "Japanese",
                        value: 1,
                        __typename: "DemographicsItem"
                    },
                    {
                        id: "ethnicity_White Eastern European",
                        name: "White Eastern European",
                        value: 1,
                        __typename: "DemographicsItem"
                    },
                    {
                        id: "ethnicity_White - English",
                        name: "White - English",
                        value: 3,
                        __typename: "DemographicsItem"
                    },
                    {
                        id: "ethnicity_White - Irish",
                        name: "White - Irish",
                        value: 2,
                        __typename: "DemographicsItem"
                    },
                    {
                        id: "ethnicity_White - Welsh",
                        name: "White - Welsh",
                        value: 1,
                        __typename: "DemographicsItem"
                    }
                ],
                __typename: "Demographics"
            },
            {
                id: "eal",
                name: "eal",
                details: [
                    {
                        id: "eal_false",
                        name: "false",
                        value: 2,
                        __typename: "DemographicsItem"
                    },
                    {
                        id: "eal_true",
                        name: "true",
                        value: 3,
                        __typename: "DemographicsItem"
                    },
                    {
                        id: "eal_",
                        name: "",
                        value: 6,
                        __typename: "DemographicsItem"
                    }
                ],
                __typename: "Demographics"
            },
            {
                id: "send",
                name: "send",
                details: [
                    {
                        id: "send_false",
                        name: "false",
                        value: 2,
                        __typename: "DemographicsItem"
                    },
                    {
                        id: "send_true",
                        name: "true",
                        value: 1,
                        __typename: "DemographicsItem"
                    },
                    {
                        id: "send_",
                        name: "",
                        value: 8,
                        __typename: "DemographicsItem"
                    }
                ],
                __typename: "Demographics"
            },
            {
                id: "premium",
                name: "premium",
                details: [
                    {
                        id: "premium_false",
                        name: "false",
                        value: 10,
                        __typename: "DemographicsItem"
                    },
                    {
                        id: "premium_true",
                        name: "true",
                        value: 1,
                        __typename: "DemographicsItem"
                    }
                ],
                __typename: "Demographics"
            }
        ],
        __typename: "StudentsClass"
    }
]
}